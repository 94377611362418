import { createSlice } from "@reduxjs/toolkit"

export const settingUserSlice = createSlice({
    name: 'settingUserData',
    initialState: { data: '' },
    reducers: {
        setRowData: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { setRowData } = settingUserSlice.actions

export default settingUserSlice.reducer