import { createSlice } from "@reduxjs/toolkit"

export const customerReturnSlice = createSlice({
    name: 'customerReturnSlice',
    initialState: { data: '' },
    reducers: {
        setRowData: (state, action) => {
            state.data = action.payload
        },
        initRowData: (state, action) => {
            state.data = ''
        }
    }
})

export const { setRowData, initRowData } = customerReturnSlice.actions

export default customerReturnSlice.reducer