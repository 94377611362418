import { createSlice } from "@reduxjs/toolkit"

export const shippingSlice = createSlice({
    name: 'shippingData',
    initialState: {
        shipping_data: {},
    },
    reducers: {
        setRowShippingData: (state, action) => {
            state.shipping_data = action.payload
        },
        setInitShippingData: (state, action) => {
            state.shipping_data = {}
        }
    }
})

export const { setRowShippingData, setInitShippingData } = shippingSlice.actions

export default shippingSlice.reducer