// ** Reducers Imports
import commonReducer from './commonSlice'
import clientReducer from './clientSlice'
import inventoryReducer from './inventorySlice'
import vendorReducer from './vendorSlice'
import customerReducer from './customerSlice'
import settingUserReducer from './settingUserSlice'
import shippingReducer from './shippingSlice'
import customerOrderReducer from './customerOrderSlice'
import vendorOrderReducer from './vendorOrderSlice'
import vendorReturnReducer from './vendorReturnSlice'
import customerReturnSlice from './customerReturnSlice'
import integrationReducer from './integrationSlice'

const rootReducer = {
    commonReducer,
    clientReducer,
    inventoryReducer,
    vendorReducer,
    customerReducer,
    settingUserReducer,
    shippingReducer,
    customerOrderReducer,
    vendorOrderReducer,
    vendorReturnReducer,
    customerReturnSlice,
    integrationReducer
}

export default rootReducer
