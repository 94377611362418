import { createSlice } from "@reduxjs/toolkit"

export const integration = createSlice({
    name: 'integrationData',
    initialState: { data: '' },
    reducers: {
        setRowData: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { setRowData } = integration.actions

export default integration.reducer